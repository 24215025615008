import React, { useState, useContext, useCallback } from "react"
import { navigate } from "gatsby"
import {
  Page,
  Layout,
  LegacyCard,
  Icon,
  LegacyStack,
  ButtonGroup,
  Button,
  TextContainer,
  FormLayout,
  Toast,
  Frame,
  Text,
  BlockStack,
  InlineStack
} from "@shopify/polaris"
import {
  ExternalSmallIcon,
  ClipboardIcon,
  CirclePlusOutlineIcon,
} from "@shopify/polaris-icons"
import { doc } from 'firebase/firestore';
import { useDocumentData } from "react-firebase-hooks/firestore"
import FirebaseContext from "../../../providers/firebase"
import styled from "styled-components"
import CreateOfferSteps from "./createOfferSteps"
import CardDivider from "../../../components/cardDivider";

const PageWrapper = styled.div`
  .Polaris-Page {
    max-width: 110rem;
  }
`
const OnboardingLastStepWrapper = styled.div`
  padding: 50px 0 10px 0;
  .preview-button {
    padding: 25px 0 15px 0;
    .Polaris-Button {
      width: 485px;
    }
  }
  .instructions {
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    .instructions-image {
      max-width: 575px;
      margin: 0 auto 20px;
      padding: 10px;
      border: 1px solid #aaaaaa;
      border-radius: 8px;
      background: #f8f8f8;
      div {
        border: 1px solid #aaaaaa;
        border-radius: 8px;
        img {
          max-width: 550px;
        }
      }
    }
  }
  .action-buttons {
    padding: 15px 0 25px 0;
    display: flex;
    justify-content: center;
    gap: 10px;
    .checkout-editor-button {
      .Polaris-Button {
        border: 2px solid #008060;
      }
    }
    .add-app-block {
      color: #2e61ce;
      font-size: 12px;
      display: flex;
      justify-content: center;
      flex-direction: row;
      svg {
        fill: #2e61ce;
      }
    }
    .save-button {
      .Polaris-Button {
        background: black;
        min-height: auto;
      }
    }
    .extension {
      border: 1px solid #ebebeb;
      padding: 8px 10px;
      border-radius: 5px;
      .extension-name {
        p:first-child {
          font-weight: bold;
          font-size: 10px;
        }
        p:nth-child(2) {
          font-size: 10px;
        }
        p:last-child {
          font-size: 8px;
          color: #727272;
        }
      }
    }
  }
`
function ProductOfferLastStep(props) {
  const { location } = props
  const backLink = location && location.state && location.state.backLink
  const extensionName =
    location && location.state && location.state.extensionName
  const id = location && location.state && location.state.id
  const { firebase, shop, token } = useContext(FirebaseContext)
  const [active, setActive] = useState(false)
  const [toastActive, setToastActive] = useState(false)
  const shopDoc = doc(firebase.firestore, 'shops', shop);
  const [shopData, shopDataLoading, shopDataError] = useDocumentData(
    shopDoc,
    {
      transform: (snap) => snap.shopData,
    }
  )
  const copyIdToClipboard = (e, id) => {
    e.stopPropagation()
    navigator.clipboard
      .writeText(id)
      .then(() => toggleActive())
      .catch((err) => console.log("Error in copy customization id: ", err))
  }
  const toggleActive = useCallback(
    () => setToastActive((active) => !active),
    []
  )

  const steps = [
    {
      title: 'Step 1: Add App block',
      description: 'Sub-description of app block',
      icon: CirclePlusOutlineIcon,
      image: 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify-dev.appspot.com/o/product-step1.svg?alt=media&token=02e8c298-2a16-4547-85b9-df4d9dceef32'
    },
    {
      title: 'Step 2: Select Product upsell',
      description: 'Sub-description of step 2',
      icon: CirclePlusOutlineIcon,
      image: 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify-dev.appspot.com/o/product-step2.svg?alt=media&token=8ddcd34f-c89a-44c1-a1ec-ad8774edcdc7'
    },
    {
      title: 'Step 3: Save',
      description: 'Sub-description of step 3',
      icon: CirclePlusOutlineIcon,
      image: 'https://firebasestorage.googleapis.com/v0/b/upsell-shopify-dev.appspot.com/o/product-step3.svg?alt=media&token=edd8d7f9-2f7e-4442-b7fa-b9be43c07f3e'
    }
  ];

  const toastMarkup = toastActive ? (
    <Toast
      content="Customization ID copied to clipboard"
      onDismiss={toggleActive}
      duration={1500}
    />
  ) : null
  const domain = shopData && shopData.domain

  return (
    <PageWrapper>
      <Page
        breadcrumbs={[{ content: "Offers", url: backLink }]}
        title={`Create new`}
        //primaryAction={{content: 'Save'}}
      >
        <Layout>
          <Layout.Section>
            <div style={{ margin: "20px 0 10px -25px" }}>
              <CreateOfferSteps step={3} />
            </div>
            <LegacyCard>
              <LegacyCard.Section>
                <OnboardingLastStepWrapper>
                  <div className="instructions">
                    <div className="instructions-image">
                      <div>
                        <img src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/onboarding-last-step.gif?alt=media&token=fffe5ba2-326e-4306-b9fc-e780dcf5b014" />
                      </div>
                    </div>
                    <FormLayout>
                      <TextContainer spacing="loose">
                        <Text variant="headingMd" as="h2">
                          Last step!
                          <br />
                          Add the Theme app extension to your theme product page
                        </Text>
                      </TextContainer>
                    </FormLayout>
                  </div>
                  {id &&
                    <div style={{padding:'15px 0'}}>
                      <LegacyStack distribution="center">
                        <div>Customization ID: {id}</div>
                        <div style={{cursor:'pointer'}} onClick={(e) => copyIdToClipboard(e, id)}>
                          <Icon source={ClipboardIcon} tone="base"/>
                        </div>
                      </LegacyStack>
                    </div>
                  }
                  <div className="action-buttons">
                    <LegacyStack
                      distribution="center"
                      alignment="center"
                      spacing="tight"
                    >
                      <Button
                        onClick={() => {
                          setActive(true)
                          window.open(
                            `https://${domain}/admin/themes/current/editor?context=apps&template=product`,
                            "_blank"
                          )
                        }}
                        variant='primary'
                      >
                        <div style={{display: "flex"}}>
                          <span>Go to theme editor</span>
                          <Icon source={ExternalSmallIcon} tone="base" />
                        </div> 
                      </Button>
                    </LegacyStack>
                    <LegacyStack distribution="center">
                      <Button
                        variant='primary'
                        onClick={() => {
                          navigate(backLink, { replace: true })
                        }}
                      >
                        Mark as done
                      </Button>
                    </LegacyStack>
                  </div>
                </OnboardingLastStepWrapper>
              </LegacyCard.Section>
              <CardDivider />
              <div style={{margin: "20px 0"}}>
              <Text variant="headingLg" alignment="center">
                Follow the steps below and get your customization ready
              </Text>
              <div style={{padding: "60px"}}>
              <Layout>
                {steps.map((step, index) => (
                  <Layout.Section key={index} variant="oneThird">
                    <LegacyCard>
                      <div style={{padding: "16px"}}>
                      <BlockStack gap="300">
                        <img src={step.image} style={{width: "100%"}} />
                        <Text variant="headingMd" as="h2">
                          {step.title}
                        </Text>
                        <Text variant="bodyMd" color="subdued">
                          {step.description}
                        </Text>
                      </BlockStack>
                      </div>
                    </LegacyCard>
                  </Layout.Section>
                ))}
              </Layout>
              </div>
              </div>
            </LegacyCard>
          </Layout.Section>
        </Layout>       
      </Page>
      <Frame>{toastMarkup}</Frame>
    </PageWrapper>
  )
}

export default ProductOfferLastStep
